import { Controller } from "@hotwired/stimulus"
import tippy, { inlinePositioning } from "tippy.js"
import { isMobile } from "../../utils/breakpoints.js"
import { evalScripts } from "../../utils/scripts.js"

class Previews extends Controller {
  static targets = ["preview"]
  static tooltipDelay = 200
  static tooltipDuration = 300
  static tooltipMaxWidth = 340
  static classHidden = "-hidden"
  static classNoPreview = "-no-preview"
  static previewableLinkSelector = "*[href]:not(.-no-preview)"

  initialize() {
    this.isMobile = isMobile()
  }

  onMouseOver(event) {
    if (this.isMobile) return
    const target = this._getPreviewableLink(event.target)
    if (!target) return
    const preview = this._getPreview(target.getAttribute("href"))
    if (!preview) return

    this.showPreview(preview, target)
  }

  onMouseOut(event) {
    if (this.isMobile) return
    const target = this._getPreviewableLink(event.target)
    if (!target) return
    const preview = this._getPreview(target.getAttribute("href"))
    if (!preview) return

    this.hidePreview(preview, target)
  }

  showPreview(preview, target) {
    this.dispatch("onMouseOver", { detail: { content: { preview, target } } })

    // Tooltips need to be initialized only once
    if (target._tippy) return

    tippy(target, {
      allowHTML: true,
      animation: "shift-toward",
      appendTo: () => document.body,
      arrow: false,
      content: preview.outerHTML.replace(Previews.classHidden, ""),
      delay: [Previews.tooltipDelay, null],
      duration: Previews.tooltipDuration,
      inlinePositioning: true,
      interactive: true,
      placement: "bottom",
      plugins: [inlinePositioning],
      theme: "disco",
      onMount: (instance) => {
        // The "locked mouse out" allows hovering the tooltip
        // with the mouse without losing the hover state
        // while preserving instant feedback
        if (this.lockedMouseOutTarget) {
          this.dispatch("onMouseOut", { detail: { content: { preview, target: this.lockedMouseOutTarget } } })
        }
        this.lockedMouseOutTarget = target
        // Evaluate htmx & scripts
        evalScripts(instance.popper)
      },
      onShow: (instance) => {
        // Disable tooltip if there is no content
        // This happens only for tweets at the moment
        const hasContent = !!instance.popper.textContent.trim()
        return hasContent
      },
      onHide: (instance) => {
        this.lockedMouseOutTarget = null
        this.dispatch("onMouseOut", { detail: { content: { preview, target } } })
      },
    })
  }

  hidePreview(preview, target) {
    if (target === this.lockedMouseOutTarget) return

    this.dispatch("onMouseOut", { detail: { content: { preview, target } } })
  }

  /* Mobile Previews */

  onLinkClicked(event) {
    if (!this.isMobile) return
    const target = this._getPreviewableLink(event.detail.link)
    if (!target) return
    const preview = this._getPreview(target.getAttribute("href"))

    // Allow navigation when double clicking on links
    if (!preview || !preview.classList.contains(Previews.classHidden)) {
      return
    }

    event.preventDefault()
    this.hideMobilePreviews()
    this.showMobilePreview(preview)
  }

  onNonlinkClicked() {
    if (!this.isMobile) return

    this.hideMobilePreviews()
  }

  showMobilePreview(preview, target) {
    preview.classList.remove(Previews.classHidden)
  }

  hideMobilePreviews() {
    for (const preview of this.previewTargets) {
      if (!preview.classList.contains(Previews.classHidden)) {
        preview.classList.add(Previews.classHidden)
      }
    }
  }

  /* Helper methods */

  _getPreview(href) {
    return this.previewTargets.find((el) => {
      return el.dataset.for === href
    })
  }

  _getPreviewableLink(link) {
    return link.closest(Previews.previewableLinkSelector)
  }
}

export { Previews }

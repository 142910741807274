import { Controller } from "@hotwired/stimulus"
import { isMobile } from "../../utils/breakpoints.js"
import { evalScripts } from "../../utils/scripts.js"

class Panel extends Controller {
  static targets = ["backButton"]
  static values = {
    goBackToUrl: String,
  }

  static appearingClass = "-appearing"
  static disappearingClass = "-disappearing"
  static hidingForwardClass = "-hidingForward"
  static bringingBackClass = "-bringingBack"
  static panelClass = "panel"
  static originalClass = "-original"
  static previousClass = "-previous"
  static nextClass = "-next"
  static appearingAnimationName = "showPanel"
  static disappearingAnimationName = "hidePanel"
  static hidingForwardAnimationName = "hidingForwardPanel"
  static bringingBackAnimationName = "bringingBackPanel"
  static lockedClass = "-locked"
  static panelPreviousClass = ".panel.-previous"

  initialize() {
    this.isMobile = isMobile()
  }

  connect() {
    if (!this.element.dataset.pageTitle) {
      this.element.dataset.pageTitle = document.title
    }
    this.update()
  }

  onClick(event) {
    const isLink = event.target.matches("a[href]")
    const target = isLink ? event.target : event.target.closest("a[href]")

    if (!target) {
      this.dispatch("nonlinkClicked", { target: event.target })
      return
    }

    const isCtrlClick = event.ctrlKey || event.shiftKey || event.metaKey
    const linkClickedEvent = this.dispatch("linkClicked", {
      detail: { isCtrlClick, link: target },
    })

    if (isCtrlClick) {
      return
    }

    if (linkClickedEvent.defaultPrevented) {
      event.preventDefault()
      return
    }

    if (this.isMobile) {
      return
    }

    const href = target.getAttribute("href")

    // If there's no preview for the link, let's not disco it
    const appearingPreview = document.querySelector(".preview-block[data-for='" + href + "']")
    if (!appearingPreview) {
      return
    }

    if (!isSameOrigin(href)) {
      return
    }

    event.preventDefault()

    this.dispatch("navigation", { detail: { href } })
  }

  navigateTo({ detail: { href } }) {
    // TODO: Replace custom fetch with htmx + view transitions when available
    fetch(href)
      .then((res) => {
        if (res.status === 204) {
          window.location.href = href
        } else {
          return res.text()
        }
      })
      .then((html) => {
        const parser = new DOMParser()
        const doc = parser.parseFromString(html, "text/html")
        const newPanel = doc.querySelector(".panel[data-controller~=panel]")
        const main = this.element.closest("main")
        const topOffset = `-${Math.round(window.scrollY)}px`

        if (newPanel) {
          newPanel.dataset.pageTitle = doc.title
          newPanel.classList.add(Panel.appearingClass)
          newPanel.classList.remove(Panel.originalClass)
          this.element.style.setProperty("margin-top", topOffset)
          this.element.classList.add(Panel.disappearingClass)
          main
            .querySelectorAll(`.panel.${Panel.nextClass}`)
            .forEach((el) => el.remove())
          main.append(newPanel)
          this.update()
          window.history.pushState({}, "", href)
          requestAnimationFrame(() => {
            window.scrollTo({ top: 0, behavior: "instant" })
          })
          // At this stage, the <script> tags of the new panel are inserted into the DOM but not executed.
          // To make article with dataviz work, we need to evaluate the scripts manually.
          evalScripts(newPanel)
        } else {
          window.location.href = href
        }
      })
  }

  _setPageTitle() {
    document.title = this.element.dataset.pageTitle
    const titleAnnouncer = document.getElementById("sr-title-announcer")
    if (titleAnnouncer) {
      titleAnnouncer.textContent = this.element.dataset.pageTitle
      titleAnnouncer.focus()
    }
  }

  onAnimationEnd(event) {
    if (event.animationName === Panel.appearingAnimationName) {
      this.element.classList.remove(Panel.appearingClass)
      this._setPageTitle()
    }
    if (event.animationName === Panel.disappearingAnimationName) {
      this.element.classList.add(Panel.previousClass)
      this.element.classList.remove(Panel.disappearingClass)
    }
    if (event.animationName === Panel.hidingForwardAnimationName) {
      this.element.classList.add(Panel.nextClass)
      this.element.classList.remove(Panel.hidingForwardClass)
      this.element.style.marginTop = null
    }
    if (event.animationName === Panel.bringingBackAnimationName) {
      this.element.classList.remove(Panel.bringingBackClass)
      this._setPageTitle()
    }
    this.toggleChildrenControllers()
  }

  onPopstate() {
    // No matching element? Redirect now
    const matching = document.querySelector(
      `.panel[data-from=${CSS.escape(location.pathname)}]`,
    )
    if (!matching) {
      window.location.reload()
    }

    this.maybeMakeActive()
    this.update()
  }

  maybeMakeActive(anchor) {
    if (new URL(location.origin + this.element.dataset.from).pathname !== location.pathname) {
      return
    }

    const allPanels = [...this.element.parentElement.children]
    const selfIndex = allPanels.indexOf(this.element)
    const previousPanels = allPanels.slice(0, selfIndex)
    const futurePanels = allPanels.slice(selfIndex + 1)

    if (this.element.classList.contains(Panel.previousClass)) {
      const oldOffset = this.element.style.marginTop

      for (const futurePanel of futurePanels) {
        if (!futurePanel.classList.contains(Panel.nextClass)) {
          futurePanel.style.marginTop =
            parseInt(oldOffset.replace(/px/, "")) * -1 + "px"
          futurePanel.classList.add(Panel.hidingForwardClass)
        }
      }

      this.element.style.marginTop = null
      this.element.classList.add(Panel.bringingBackClass)
      this.element.classList.remove(Panel.previousClass)

      if (anchor) {
        const anchorElement = document.getElementById(anchor)
        anchorElement.scrollIntoView({ behavior: "instant" })
        anchorElement.focus()
      } else if (oldOffset) {
        requestAnimationFrame(() => {
          window.scrollTo({
            top: parseInt(oldOffset.replace(/px/, "")) * -1,
            behavior: "instant",
          })
        })
      }
    }

    if (this.element.classList.contains(Panel.nextClass)) {
      const topOffset = `-${Math.round(window.scrollY)}px`

      for (const previousPanel of previousPanels) {
        if (!previousPanel.classList.contains(Panel.previousClass)) {
          previousPanel.style.setProperty("margin-top", topOffset)
          previousPanel.classList.add(Panel.disappearingClass)
        }
      }

      this.element.classList.add(Panel.appearingClass)
      this.element.classList.remove(Panel.nextClass)
    }
  }

  goBack() {
    if (this.goBackToUrlValue) {
      this.goBackTo(this.goBackToUrlValue)
      this.goBackToUrlValue = undefined
    } else {
      window.history.back()
    }
  }

  /**
   * Navigate to a given URL which contents is supposed to be already loaded in another "previous" panel:
   * - From the History perspective: the current URL is replaced.
   * - From the panels perspective: the animation feels like going back.
   * This weird behavior exists to initiate panels navigation by going "back" to a given URL
   * while the user actually comes from an e-mail for example.
   */
  goBackTo(url) {
    window.history.pushState(null, "", url)
    return this.getPreviousPanel(url)
  }

  async getPreviousPanel(url) {
    let previousPanel = document.querySelector(Panel.panelPreviousClass)

    if (!previousPanel) {
      previousPanel = await new Promise((resolve) => {
        const onLoad = (event) => {
          const loadedElement = event.detail.elt
          if (loadedElement.matches(Panel.panelPreviousClass)) {
            document.removeEventListener("htmx:load", onLoad)
            resolve(loadedElement)
          }
        }

        document.addEventListener("htmx:load", onLoad)
      })
    }

    const newUrl = new URL(location.origin + url)
    const anchor = newUrl.hash.slice(1)
    window.stimulus.getControllerForElementAndIdentifier(previousPanel, "panel").maybeMakeActive(anchor)
  }

  toggleLayoutFreeze() {
    const allPanels = document.querySelectorAll(`.${Panel.panelClass}`)

    for (const panel of allPanels) {
      const isCurrentPanel = panel.classList.contains(Panel.appearingClass) || panel.classList.contains(Panel.originalClass)

      if (isCurrentPanel) {
        const layout = panel.parentNode
        const footer = document.querySelector(".layout-footer")

        if (panel.dataset.isEditorialLocked === "True") {
          layout.classList.add(Panel.lockedClass)
          footer.classList.add(Panel.lockedClass)
        } else {
          layout.classList.remove(Panel.lockedClass)
          footer.classList.remove(Panel.lockedClass)
        }
      }
    }
  }

  toggleChildrenControllers() {
    const allPanels = document.querySelectorAll(`.${Panel.panelClass}`)
    const activeAttribute = "data-controller"
    const inertAttribute = "data-inert-controller"

    if (allPanels) {
      allPanels.forEach((panel) => {
        if (panel.classList.contains(Panel.disappearingClass) || panel.classList.contains(Panel.hidingForwardClass) || panel.classList.contains(Panel.previousClass) || panel.classList.contains(Panel.nextClass)) {
          const controllers = panel.querySelectorAll(`[${activeAttribute}]`)
          controllers && controllers.forEach((controller) => {
            const value = controller.getAttribute(activeAttribute)
            controller.removeAttribute(activeAttribute)
            controller.setAttribute(inertAttribute, value)
          })
        } else {
          const inertControllers = panel.querySelectorAll(`[${inertAttribute}]`)
          inertControllers && inertControllers.forEach((controller) => {
            const value = controller.getAttribute(inertAttribute)
            controller.removeAttribute(inertAttribute)
            controller.setAttribute(activeAttribute, value)
          })
        }
      })
    }
  }

  update() {
    this.toggleLayoutFreeze()
    this.toggleChildrenControllers()
  }
}

function isSameOrigin(href) {
  if (href.startsWith("/")) {
    return true
  } else {
    try {
      const currentUrl = new URL(window.location.href)
      const url = new URL(href)

      return currentUrl.origin === url.origin
    } catch {
      return false
    }
  }
}

export { Panel }

import { Controller } from "@hotwired/stimulus"

class ChipField extends Controller {
  static targets = ["uncheckedIcon", "checkedIcon"]
  static classHidden = "-hidden"

  toggleCheckbox() {
    const checkbox = this.element.querySelector("input[type=checkbox]")
    checkbox.checked = !checkbox.checked

    this.checkedIconTarget.classList.toggle(ChipField.classHidden, !checkbox.checked)
    this.uncheckedIconTarget.classList.toggle(ChipField.classHidden, checkbox.checked)
  }
}

export { ChipField }

import { Controller } from "@hotwired/stimulus"
import { log } from "../utils/logging.js"
import { Matomo } from "./matomo.js"
import { PostHog } from "./posthog.js"

class Tracking extends Controller {
  static targets = ["env", "search"]

  connect() {
    // Expose instance to Matomo Tag Manager
    window.tracking = this

    // Init tracking services
    this.matomo = new Matomo()
    this.posthog = new PostHog()

    // Init posthog event queue
    const env = this.getEnv()
    this.posthog.init(env.posthogToken)
  }

  // Init is called by CookiesConsent controller
  init({ detail: choices }) {
    const env = this.getEnv()
    // Matomo Tag Manager is enabled by default, and consent is handled inside it
    this.matomo.initTagManager(env.matomoContainerId)
    // Init Matomo
    this.matomo.init(choices.Matomo, env.matomoTrackerUrl, env.matomoSiteId)
    // Enable PostHog event tracking
    this.posthog.enableTracking(choices.PostHog)

    // Track initial page view
    this.trackPageView()

    // Track history page view (https://stackoverflow.com/a/64927639)
    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        const result = target.apply(thisArg, argArray)
        this.trackPageView()
        return result
      },
    })
  }

  // --- Public methods

  // Track Page Views on load
  trackPageView() {
    const params = this.getPageParams()
    if (!params.pageType) {
      throw new InvalidTrackingError("Page type must be provided")
    }
    log("trackPageView", params)
    switch (params.pageType) {
      case "search":
        this.matomo.trackSiteSearch({
          ...params,
          ...this.searchTarget.dataset,
        })
        this.posthog.trackEvent("Search", "Search", {
          ...params,
          ...this.searchTarget.dataset,
        })
        break
      default:
        this.matomo.trackPageView(params)
        this.posthog.trackPageView(params)
    }
  }

  // Track Events from code and Matomo Tag Manager
  trackEvent(eventCategory, eventName, params = {}) {
    if (!eventCategory || !eventName) {
      throw new InvalidTrackingError("Event name and category must be provided")
    }
    log("trackEvent", params)
    this.matomo.trackEvent(eventCategory, eventName, params)
    this.posthog.trackEvent(eventCategory, eventName, params)
  }

  // Track Events from templates
  trackTemplateEvent(event) {
    const { target, params } = event
    const { eventCategory, eventName } = params
    // Get search query from text input
    if (target.type === "text" && target.value) {
      params.searchQuery = params.searchQuery || event.target.value
      params.objectName = params.objectName || event.target.value
    }
    return this.trackEvent(eventCategory, eventName, params)
  }

  // --- Internal methods

  // Read env exposed by template tracking.html
  getEnv() {
    return (this.envTarget && this.envTarget.dataset) || {}
  }

  getPageTitle() {
    return document.title.replace(/(Contexte - )|( - Contexte)|( \| Contexte Scan)/, "")
  }

  getPageType(env) {
    const pathParts = location.pathname.split("/")
    const isAmendment = pathParts[4] && pathParts[4].startsWith("amendment-")
    const isMotion = pathParts[4] && pathParts[4].startsWith("motion-")
    const isBill = pathParts[1] && pathParts[1].startsWith("bill-")
    if (isAmendment) {
      return "amendment"
    } else if (isMotion) {
      return "motion"
    } else if (isBill) {
      return "bill"
    } else if (env.pageType) {
      return env.pageType
    } else {
      return "page"
    }
  }

  getPageParams() {
    const env = this.getEnv()
    return {
      pageTitle: this.getPageTitle(),
      pageType: this.getPageType(env),
      pageGeo: env.pageGeo,
      userId: env.userId,
      userSectors: env.userSectors,
      userType: env.userType,
    }
  }
}

class InvalidTrackingError extends Error {
  constructor(message) {
    super(message)
    this.name = "InvalidTrackingError"
  }
}

export { Tracking }

import { Controller } from "@hotwired/stimulus"

class Alerts extends Controller {
  static targets = ["alertList"]
  static values = { alertId: String }

  connect() {
    this.scroll()
  }

  scroll() {
    if (!this.alertIdValue) return
    const element = document.getElementById(`alerts-alert-${this.alertIdValue}`)
    element?.scrollIntoView({ behavior: "smooth", block: "center" })
  }

  cleanDuplicatedListLabels() {
    const listLabels = {}
    this.alertListTarget.querySelectorAll(".alerts__sublist-title").forEach(elt => {
      if (!(elt.textContent in listLabels)) {
        listLabels[elt.textContent] = 1
      } else {
        elt.remove()
      }
    })
  }

  cleanListDisplay(event) {
    if (!event.target || !event.target.className) return
    if (event.target.className.indexOf("alerts__item") !== -1) {
      this.removeOldSelected()
    } else if (event.target.className.indexOf("alerts__sublist-title") !== -1) {
      this.cleanDuplicatedListLabels()
    }
  }

  removeOldSelected() {
    this.alertListTarget.querySelectorAll(".alerts__item.-selected").forEach(elt => {
      elt.classList.remove("-selected")
    })
  }
}

export { Alerts }

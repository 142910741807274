import * as htmx from "htmx.org"

export function loadScript(src) {
  if (document.querySelector("script[src='" + src + "']")) {
    return Promise.reject(new Error("Script already loaded: " + src))
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.onload = resolve
    script.onerror = reject
    script.src = src
    document.head.append(script)
  })
}

export function evalScripts(node) {
  // Eval htmx
  htmx.process(node)

  // Eval scripts
  const scripts = node.querySelectorAll("script")
  scripts.forEach(script => {
    // Inspired by https://github.com/bigskysoftware/htmx/blob/6fe62580e001d10b1d6fe4e27e778c3f23bb2f75/src/htmx.js#L1843-L1867
    if (script.type === "text/javascript" || script.type === "module" || script.type === "") {
      const newScript = document.createElement("script")
      Array.from(script.attributes).forEach((attr) => {
        newScript.setAttribute(attr.name, attr.value)
      })
      // Dispatch window.onload manually to tell the script that the page is loaded
      newScript.onload = () => {
        dispatchEvent(new Event("load"))
      }
      newScript.textContent = script.textContent
      newScript.async = false
      if (htmx.config.inlineScriptNonce) {
        newScript.nonce = htmx.config.inlineScriptNonce
      }
      const parent = script.parentElement

      try {
        parent.insertBefore(newScript, script)
      } catch (e) {
        console.log(e)
      } finally {
        if (script.parentElement) {
          script.parentElement.removeChild(script)
        }
      }
    }
  })
}

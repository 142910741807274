import { Controller } from "@hotwired/stimulus"

class SubscriptionsForm extends Controller {
  static targets = ["options", "otherChoiceField", "submit", "banner"]
  static outlets = ["select"]
  static classHidden = "-hidden"
  static values = {
    // if selected, will unhide a new text input
    other: String,
  }

  connect() {
    this.toggleOptinAdvice()
    this.toggleOptionalField()
    this.enableFormSubmission()
    this.element.addEventListener("submit", () => { this.submitTarget.disabled = true })
  }

  toggleOptionalField() {
    if (this.hasSelectOutlet) {
      const checkedValue = this.selectOutletElement.querySelector("input:checked")
      if (!checkedValue) return

      if (checkedValue.value === this.otherValue) {
        this.otherChoiceFieldTarget.classList.remove(SubscriptionsForm.classHidden)
        this.otherChoiceFieldTarget.querySelector("input").required = true
      } else {
        this.otherChoiceFieldTarget.classList.add(SubscriptionsForm.classHidden)
        this.otherChoiceFieldTarget.querySelector("input").required = false
      }
    }
    this.enableFormSubmission()
  }

  enableFormSubmission() {
    /* Check required inputs before enabling form submission */
    const requiredInputs = this.element.querySelectorAll("input[required]")

    const inputs = [...requiredInputs].filter(input => input.type !== "checkbox" && input.type !== "radio")
    const hasEmptyRequiredInput = inputs.some(input => input.value.trim() === "")
    const passwordInput = this.element.querySelector("input[type='password']")
    const hasUnFilledRequirements = passwordInput && passwordInput.value.length < 8

    const hasUncheckedOption = this.optionsTargets.some((option) => {
      const checkedInputs = option.querySelectorAll("input:checked")
      return !checkedInputs || checkedInputs.length === 0
    })

    this.submitTarget.disabled = hasEmptyRequiredInput || hasUncheckedOption || hasUnFilledRequirements
  }

  toggleOption(e) {
    const input = e.target.firstElementChild || e.target
    input.checked = !input.checked
  }

  toggleOptinAdvice() {
    const checkedInputs = this.optionsTarget.querySelectorAll("input[type='checkbox']:checked")
    if (checkedInputs.length > 3) this.bannerTarget.classList.remove(SubscriptionsForm.classHidden)
    else this.bannerTarget.classList.add(SubscriptionsForm.classHidden)
  }
}

export { SubscriptionsForm }
